export const MerchTypes = [
  {
    name: "小微商户",
    value: 1,
  },
  {
    name: "个体户",
    value: 2,
  },
  {
    name: "企业",
    value: 3,
  },
];

export const OperatorMenus = [
  {
    title: "场地管理",
    icon: "iconfont-changdiguanli",
    to: { path: "/point/index" },
    color: "#F1594C",
    key: "site",
    auth: "B0401",
  },
  {
    title: "子账号管理",
    icon: "iconfont-zizhanghaoguanli",
    to: { path: "/sub-account/index" },
    color: "#1890FF",
    key: "subaccount",
    auth: "B0402",
  },
  {
    title: "实名认证",
    icon: "iconfont-shimingrenzheng",
    to: { path: "/user/certification" },
    key: "vertifi",
    color: "#09C099",
    auth: "B0403",
  },
  {
    title: "绑定微信账号",
    icon: "iconfont-weixinbangding",
    to: { path: "/login/bind" },
    key: "wxbind",
    color: "#00c800",
    auth: "",
  },
];
export const SettingMenus = [
  {
    to: {
      path: "/forget",
      query: { type: "update" },
    },
    title: "修改密码",
    key: "updatePassword",
    auth: "",
  },
];

export const VendorMenus = [
  {
    to: {
      path: "/forget",
      query: { type: "update" },
    },
    title: "修改密码",
    key: "updatePassword",
    auth: "",
  },
  {
    to: null,
    title: "生成邀请码",
    key: "InvitationCode",
    auth: "",
  },
  {
    to: null,
    title: "绑定微信账号",
    key: "wxbind",
    auth: "",
  },
];
