<template>
  <Page>
    <div class="user-info-box content">
      <div v-show="!loading" class="u-flex info-content">
        <!-- 设置 -->
        <div
          v-if="Role === 'Operator'"
          class="setting-icon"
          @click="handleToSetting"
        >
          <van-icon name="setting-o" />
        </div>
        <!-- 头像 -->
        <div style="width: 72px">
          <van-image
            round
            width="72px"
            height="72px"
            :src="
              WxInfo?.headImgUrl || ImagePrefix + 'vending/pic/h5B/touxiang.png'
            "
          />
        </div>
        <!-- 信息 -->
        <div class="user-info u-flex-1 u-flex u-flex-column u-m-l-12 u-col-top">
          <div class="u-flex u-flex-wrap">
            <span class="u-font-18">{{ userInfo.userName || "--" }}</span>
            <div
              v-for="role in userInfo.roleName"
              :key="role"
              class="user-role u-flex u-row-center u-font-12 content"
            >
              {{ role }}
            </div>
          </div>
          <p class="u-font-16 u-m-t-12">{{ userInfo.phone || "" }}</p>
        </div>
      </div>
      <div v-if="loading" class="info-content u-flex">
        <van-skeleton avatar-size="72" title avatar :row="1" />
      </div>
    </div>

    <!-- 菜单 -->
    <div class="u-m-t-12">
      <template v-if="Role === 'Operator'">
        <OperatorMenu />
      </template>
      <template v-if="Role === 'Vendor'">
        <VendorMenu :mch-id="userInfo.mchId" />
      </template>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onActivated } from "vue";
import { useRouter } from "vue-router";
import { getUserInfoDetailApi } from "@/api/user";
import { factoryGetUserInfoApi } from "@/api/vendor";
import { getCurrentRole, isOperator } from "@/hooks/useRole";
import { LocalStorageEnum } from "@/hooks/useLocalStorage";
import OperatorMenu from "./components/OperatorMenu.vue";
import VendorMenu from "./components/VendorMenu.vue";
export default defineComponent({
  name: "User",
  components: { OperatorMenu, VendorMenu },
  inject: ["ImagePrefix"],
  setup() {
    const router = useRouter();
    const userInfo = ref({});
    const Role = getCurrentRole();
    const loading = ref(true);
    let WxInfo = {};
    try {
      WxInfo = JSON.parse(localStorage.getItem(LocalStorageEnum.WX_INFO));
    } catch (error) {
      console.log("error", error);
    }
    onActivated(() => {
      getUserInfoDetail();
    });
    const getUserInfoDetail = async () => {
      loading.value = true;
      try {
        const userAction = isOperator()
          ? getUserInfoDetailApi
          : factoryGetUserInfoApi;
        console.log("userAction", userAction);
        const { code, content } = await userAction();
        if (code === 200) {
          let temp = {
            ...content,
            roleName: content.roleName.split(","),
          };
          localStorage.setItem(LocalStorageEnum.USER, JSON.stringify(temp));
          userInfo.value = temp;
          setTimeout(() => {
            loading.value = false;
          }, 1000);
        }
      } catch (error) {
        console.log("error", error);
        loading.value = false;
      }
    };
    const handleToSetting = () => {
      console.log("设置");
      router.push({
        path: "/user/setting",
      });
    };
    return {
      WxInfo,
      loading,
      Role,
      userInfo,
      handleToSetting,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-info-box {
  width: 100%;
  position: relative;
  padding: 24px 12px;
  background-color: #027aff;
}
.info-content {
  width: 100%;
  height: 100%;
  :deep(.van-skeleton) {
    padding: 0;
    width: 100%;
  }
}
.setting-icon {
  position: absolute;
  right: 16px;
  top: 12px;
  font-size: 20px;
  color: #ffffff;
}
.user-info {
  color: #ffffff;
  .user-role {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    vertical-align: middle;
    height: 20px;
    box-sizing: border-box;
    margin: 2px 4px;
    line-height: normal;
  }
}
</style>
