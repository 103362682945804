<!--  -->
<template>
  <div class="custom-cell">
    <van-cell-group>
      <van-cell
        v-for="menu in VendorMenusList"
        :key="menu.key"
        :title="menu.title"
        is-link
        @click="handleClickCell(menu)"
      />
    </van-cell-group>
  </div>
  <div class="u-m-t-12 log-out" @click="handleLogOut">退出登录</div>
  <van-popup
    v-model:show="isShowQrCodePop"
    teleport="body"
    :lock-scroll="true"
    :close-on-popstate="true"
    round
    title="邀请码"
  >
    <div class="pop-content u-text-center">
      <div
        ref="qrCode"
        class="qrcode qrcode-img u-flex u-row-center"
        :style="{
          display: qrcodeImage ? 'none' : '',
        }"
      />
      <div v-if="qrcodeImage" class="qrcode-img">
        <van-image width="200px" height="200px" :src="qrcodeImage" />
      </div>
      <!-- <van-button type="primary" round @click="downloadQrcode"
          >下载二维码</van-button
        > -->
      <div
        class="u-font-14 u-tips-color u-text-center u-line-height-22 u-m-b-24"
      >
        长按图片可保存到手机
      </div>
      <div
        class="u-m-24 u-line-height-22 u-theme-color u-text-center u-font-16"
        @click="handleCopyInvitationUrl"
      >
        复制邀请链接
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, ref, onActivated, inject, nextTick } from "vue";
import { useRouter } from "vue-router";
import { VendorMenus } from "../const";
import { useActions } from "@/hooks/useVuex";
import { logoutApi } from "@/api/user";
import { factoryLogoutApi } from "@/api/vendor";
import { isOperator } from "@/hooks/useRole";
import { useCopy } from "@/hooks/useCopy";
import { Dialog } from "vant";
import QRCode from "qrcodejs2";
export default defineComponent({
  name: "VendorMenu",
  props: {
    mchId: {
      type: String,
      default: "",
    },
  },
  components: {},
  setup(props) {
    const router = useRouter();
    const qrcodeText = ref("");
    const VendorMenusList = ref([]);
    const qrcodeImage = ref("");
    const isShowQrCodePop = ref(false);
    const qrCode = ref(null);
    const useAuth = inject("useAuth");
    const { resetLogin } = useActions("user", ["resetLogin"]);
    const { checkWeixinBind } = useActions("user", ["checkWeixinBind"]);
    onActivated(() => {
      VendorMenusList.value = VendorMenus.filter((m) => {
        return useAuth(m.auth);
      });
    });

    // 前端生成二维码
    const createQrcode = () => {
      console.log("createQrcode");
      qrCode.value && (qrCode.value.innerHTML = ""); // 创建之前需要把上次创建的删除
      const Domian = `${process.env.VUE_APP_REDIRECT_DOMIAN}/redirect?r=1&mchId=${props.mchId}`;
      const APPID = process.env.VUE_APP_APPID;
      qrcodeText.value = `http://h5.lerke.cn/get-weixin-code.html?appid=${APPID}&scope=snsapi_userinfo&redirect_uri=${encodeURIComponent(
        Domian
      )}`;
      new QRCode(qrCode.value, {
        width: 200,
        height: 200,
        text: qrcodeText.value,
      });
      // 加个延迟，不然display一直是空
      setTimeout(() => {
        const Canvas = document.getElementsByTagName("canvas")[0];
        const display = Canvas.style.display;
        if (display === "none") return false;
        const AndriodImgUrl = Canvas.toDataURL("image/png");
        qrcodeImage.value = AndriodImgUrl;
      }, 100);
    };

    const handleClickCell = (menu) => {
      console.log(menu);
      if (menu.key === "InvitationCode") {
        console.log("生成邀请码");
        isShowQrCodePop.value = true;
        nextTick(() => {
          createQrcode();
        });
        return false;
      }
      // 微信绑定
      if (menu.key === "wxbind") {
        checkWeixinBind();
        return false;
      }
      router.push(menu.to);
    };

    const handleLogOut = () => {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定退出登录？",
        confirmButtonColor: "#027AFF",
      })
        .then(() => {
          // on close
          logout();
        })
        .catch(() => {
          // on cancel
        });
    };
    const logout = async () => {
      // 不管接口响应啥都得退出去
      const logoutAction = isOperator() ? logoutApi : factoryLogoutApi;
      try {
        await logoutAction();
        resetLogin();
      } catch (error) {
        console.log("error", error);
        resetLogin();
      }
    };
    const handleCopyInvitationUrl = () => {
      useCopy(qrcodeText.value);
    };
    return {
      qrCode,
      qrcodeText,
      isShowQrCodePop,
      qrcodeImage,
      VendorMenusList,
      handleLogOut,
      handleClickCell,
      handleCopyInvitationUrl,
    };
  },
});
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.custom-cell {
  :deep(.van-cell-group) {
    margin: 0;
  }
}
.log-out {
  height: 46px;
  color: #fa2d19;
  text-align: center;
  line-height: 46px;
  background-color: #ffffff;
}
.pop-content {
  // width: 300px;
  // height: 280px;
  // padding: 30px;
  box-sizing: border-box;
  .qrcode-img {
    padding: 30px 56px;
  }
  img {
    padding: 42px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
  }
}
</style>
