<!--  -->
<template>
  <van-cell-group v-if="OperatorMenusList.length" inset>
    <van-cell
      v-for="menu in OperatorMenusList"
      :key="menu.icon"
      :title="menu.title"
      is-link
      @click="handleClickCell(menu)"
    >
      <template #icon>
        <i
          class="iconfont u-m-r-12"
          :class="[menu.icon]"
          :style="{ color: menu.color, fontSize: '20px' }"
        />
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script>
import { defineComponent, ref, onActivated, inject } from "vue";
import { useRouter } from "vue-router";
import { OperatorMenus } from "../const";
import { useState, useActions } from "@/hooks/useVuex";
export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const OperatorMenusList = ref([]);
    const useAuth = inject("useAuth");
    const { mchCertStatus } = useState("app", ["mchCertStatus"]);
    const { checkWeixinBind } = useActions("user", ["checkWeixinBind"]);
    onActivated(() => {
      OperatorMenusList.value = OperatorMenus.filter((m) => {
        return useAuth(m.auth);
      });
    });
    const handleClickCell = (menu) => {
      console.log(menu);
      // 实名认证
      if (menu.key === "vertifi" && mchCertStatus.value.status) {
        router.push({
          path: "/user/certification/status",
          query: {
            status: mchCertStatus.value.status,
            rejectReason: mchCertStatus.value.rejectReason,
          },
        });
        return false;
      }
      // 微信绑定
      if (menu.key === "wxbind") {
        checkWeixinBind();
        return false;
      }
      router.push(menu.to);
    };
    return {
      OperatorMenusList,
      handleClickCell,
    };
  },
});
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
